import { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import DarkMode from "@mui/icons-material/DarkMode";
import LightMode from "@mui/icons-material/LightMode";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import HomeIcon from "@mui/icons-material/Home";
import Editor from "./components/Editor";
import RIcon from "./components/RIcon";
import { useParams } from "react-router-dom";

function App() {
  const { gistId, fileName } = useParams();

  const gistApiUrl = "https://api.github.com/gists/";
  const gistUrl = gistApiUrl + gistId;

  const defaultValue = "<h1 style='color: red;'>Hello World!</h1>";

  const [htmlValue, setHtmlValue] = useState(defaultValue);
  const [outputValue, setOutputValue] = useState(defaultValue);

  const [isDark, setDarkMode] = useState(false);

  useEffect(() => {
    if (gistId != null && fileName != null) {
      fetch(gistUrl)
        .then((response) => response.json())
        .then((data) => data.files[fileName].content)
        .then((data) => {
          setHtmlValue(data);
          setOutputValue(data);
        });
    }
  }, []);

  // New Version

  function runCode() {
    const output = htmlValue;
    setOutputValue(output);
  }

  function toggleDarkMode() {
    setDarkMode(!isDark);
  }

  return (
    <div className="container">
      <div className={isDark ? "dark" : ""}>
        <div className="w-screen h-screen bg-gray-300 dark:bg-gray-500">
          <div className="h-20 w-screen text-gray-800 bg-gray-100 dark:bg-gray-800 dark:text-gray-100 flex justify-center items-center font-mono font-bold text-5xl">
            Editor-R
          </div>
          <div className="w-screen h-11 my-1 px-2 flex items-center">
            <div className="w-60 h-full flex items-center space-x-2">
              <RIcon>
                <a href="https://www.rareprogrammer.com">
                  <HomeIcon />
                </a>
              </RIcon>
              <RIcon>
                <ChangeCircle />
              </RIcon>
              <button onClick={toggleDarkMode}>
                <RIcon>{isDark ? <LightMode /> : <DarkMode />}</RIcon>
              </button>

              <button
                onClick={runCode}
                className="w-20 h-9 p-1 text-center font-bold text-gray-800 bg-green-100 hover:bg-green-800 hover:text-gray-100 shadow-none hover:shadow-none rounded-md"
              >
                Run{" "}
                <ArrowForwardIos style={{ width: "1rem", height: "1rem" }} />
              </button>
            </div>
          </div>
          <SplitPane
            style={{ height: "30rem" }}
            split="vertical"
            minSize={"50%"}
          >
            <div className="bg-white overflow-hidden mx-2">
              <Editor
                value={htmlValue}
                onChange={setHtmlValue}
                darkMode={isDark}
              />
            </div>
            <div className="bg-white h-full mx-2">
              <iframe
                srcDoc={outputValue}
                className="w-full h-full border-none"
              />
            </div>
          </SplitPane>
        </div>
      </div>
    </div>
  );
}

export default App;

const styles = {
  runButton: {
    backgroundColor: "green",
    boxShadow: "none",
    borderRadius: "0.375rem",
  },
};
