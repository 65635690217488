import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-one_dark";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/html";

export default function Editor({ onChange, value, darkMode }) {
  return (
    <div>
      <AceEditor
        mode="html"
        theme={darkMode ? "one_dark" : "xcode"}
        name="Code"
        focus={true}
        fontSize={18}
        width={"100%"}
        height="30rem"
        showPrintMargin={true}
        showGutter={false}
        tabSize={2}
        setOptions={{ useWorker: true }}
        onChange={onChange}
        value={value}
        enableBasicAutocompletion={true}
        enableLiveAutocompletion={true}
        enableSnippets={true}
        editorProps={{ $blockScrolling: true }}
      />
    </div>
  );
}
